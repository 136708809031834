header.siteHeader {
  h5 {
    color: #292727;
  }
  .logo {
    img {
      height: 23px;
      object-fit: contain;
    }
  }
  .searchForm {
    input {
      background: #fafafa;
      border: 1px solid #fafafa;
      height: 40px;
      min-width: 300px;
      font-size: 12px;
      color: #c4c4c4;
      padding-left: 35px;
      &::placeholder {
        color: #c4c4c4;
      }
    }
  }
  .notificationDropdown {
    .count {
      height: 16px;
      width: 16px;
      right: -2px;
      top: -6px;
      font-size: 9px;
      line-height: 12px;
      background: rgb(249 60 101 / 77%);
      border: 2px solid rgb(255 223 230 / 24%);
    }
    .menu {
      left: unset !important;
      right: 0;
      min-width: 500px;
      .top {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        h4 {
          font-size: 18px;
          .count {
            background-color: #0085ff;
            height: 25px;
            width: 25px;
            font-size: 14px;
          }
        }
      }
      .notificationList {
        > li:not(:last-child) {
          border-bottom: 1px solid #dcdee4;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
  .profileLink {
    font-size: 12px;
    line-height: 14px;
    img {
      border: 2px solid #dfe0eb;
    }
  }
}
