.authLayout {
  line-height: 20px;
  background-color: #292727;
  .link {
    transition: 0.4s;
    &:hover {
      color: #860000 !important;
    }
  }
  .logo {
    img {
      height: 150px;
      object-fit: contain;
    }
  }
  .formWrpper {
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .innerForm {
      box-shadow: 0px 0px 14px rgba(0, 0, 0, 15);
      border-radius: 10px;
      background: linear-gradient(227.51deg, #eff4fb 1.98%, #c5d5ed 95.16%);
    }
    .form {
      .formControl {
        border: 1px solid #fff;
        height: 40px;
        font-size: 12px;
        font-weight: 500;
        &::placeholder {
          color: #b6b3b3;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .authLayout {
    height: 100vh;
    .formWrpper {
      height: auto !important;
      border-radius: 10px;
      padding: 20px 15px !important;
      background: linear-gradient(227.51deg, #eff4fb 1.98%, #c5d5ed 95.16%);
      .imgWrpper {
        border-right: 0 !important;
      }
      .innerForm {
        background: transparent;
        padding: 0 !important;
        box-shadow: unset !important;
      }
    }
  }
}
